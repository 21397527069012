@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
//@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

html {
    font-size: 16px;
}

ul {
    list-style: none;
}

body {
    margin: 0px;
    font-size: 1rem;
    color: #333;
    font-family: "Noto Sans Japanese", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS P Gothic", Osaka, Verdana, Helvetica, sans-serif;

}

#wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: solid 1px #ccc;
}

#header {
    width: 100%;
    height: 60px;
    position: fixed;
    background: #fff;
    z-index: 10;
    #catch {
        font-size: 0.6rem;
        padding-left: 10px;
        line-height: 14px;
        height: 14px;
    }
    #logo {
        height: 38px;
        line-height: 38px;
        padding-right: 62px;
        position: fixed;
    }
    #info {
        display: none;
    }
    .menuBtn {
        color: #fff;
        padding: 5px;
        width: 60px;
        height: 60px;
        position: absolute;
        z-index: 1000;
        right: 0;
        top: 0;
        background-color: $dark-gray;
        -webkit-transition: background-color 0.4s;
        -moz-transition: background-color 0.4s;
        -ms-transition: background-color 0.4s;
        -o-transition: background-color 0.4s;
        transition: background-color 0.4s;
        p {
            margin: 0;

        }
    }

}

#globalNav {
    display: none;
}

#side_btn {
    display: none;
}

.off-canvas {
    ul {
        list-style: none;

    }
    ul.accordion-menu {
        margin: 12px 8px 8px 16px;
        text-align: left;
        display: table;
        font-size: 1.1rem;
        li {
            margin-bottom: 6px;
        }
        a {
            color: #fff;
        }
    }
    .button_list {
        margin: 20px 0 20px 24px;
        font-size: 1.1rem;
        li a {
            width: 180px;
        }
    }
}

.hero {
    padding: 35px 0 15px;
    background: $light-gray;
    font-size: 0.9rem;
    .hero-body {
        margin: 0px auto;
        display: table;
    }
}

.hero-section {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 40px;
    height: 100px;
    background: linear-gradient(rgba(36, 39, 38, 0.5), rgba(36, 39, 38, 0.5)), rgba(36, 39, 38, 0.5) url("/assets/templates/men/images/hero-img.jpg") no-repeat center;
    background-size: auto auto, auto auto;
    background-size: cover;
}

.hero-title {
    padding-top: 15px;
    padding-bottom: 30px;
    font-size: 1.3rem;
    color: #fff;
    line-height: 30px;
    font-weight: 600;
}

@media screen and (min-width: 600px) {
    .hero-section {
        padding-top: 50px;
        padding-bottom: 20px;
        padding-left: 70px;
        height: 130px;
    }
    .hero-title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 768px) {
    .hero {
        padding-top: 80px;
        h2 {
            font-size: 1.4rem;
        }
    }
    .hero-section {
        padding-top: 100px;
        padding-bottom: 20px;
        padding-left: 80px;
        height: 200px;
    }
    .hero-title {
        font-size: 1.7rem;
    }
}

@media screen and (min-width: 900px) {
    #header {
        width: 100%;
        height: 90px;
        position: relative;
        background: #fff;
        z-index: 300;
        .menuBtn {
            display: none;
        }
        #catch {
            font-size: 0.6rem;
            top: 3px;
            left: 10px;
            line-height: 15px;
            height: 15px;
            position: absolute;
        }
        #logo {
            top: 20px;
            height: 52px;
            line-height: 52px;
            padding-left: 10px;
            position: absolute;
        }
        #info {
            display: block;
            position: absolute;
            right: 4px;
            top: 18px;
            width: 210px;
            font-size: .85rem;
            color: #666666;
            .tel {
                font-size: 1.6rem;
                color: #505050;
            }
            .telnum {
                font-size: 1.6rem;
                color: #A7792E;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
                text-decoration: none;
            }
        }
        #head_btn {
            position: absolute;
            right: 240px;
            top: 35px;
            li {
                display: inline;
            }
            li.active {
                display: none;
            }
        }
    }
    nav {

        #globalNav {

            display: block;
            height: 46px;
            line-height: 46px;
            background: $dark-gray;
            ul.dropdown li {
                color: #fff;
                a {
                    color: #fff;
                    height: 46px;
                }
            }
        }
    }
    .hero {
        padding: 15px 0 15px;
        background: $light-gray;
    }
    .hero-section {
        padding-top: 60px;
        padding-bottom: 20px;
        padding-left: 120px;
        height: 200px;
    }
    .hero-title {
        font-size: 2rem;
    }
}

#maincontent {
    width: 100%;
    padding: 10px 10px 10px;
}

#footer {
    width: 100%;
    padding: 5px 0px 59px;
    background: $light-gray;
    margin: 0;
    h3 {
        color: $red;
        font-weight: bold;
        padding: 5px 20px 5px;
    }
    ul {
        list-style: none;
    }
    .foot-menu {
        margin: 15px auto 20px;
        display: table;
        li {
            margin-top: 5px;
            a {
                color: $gold;
                position: relative;
            }
            a:before {
                content: "\f0da";
                font-family: 'FontAwesome';
                position: absolute;
                left: -10px;
            }
        }
    }
    .clinic_info {
        margin: 10px auto 5px;
        display: table;
        font-size: 0.8rem;

    }
    #foot_map {
        margin: 5px auto 20px;
        display: table;
    }
    .copyright {
        width: 100%;
        height: 45px;
        line-height: 45px;
        background: $dark-gray;
        small {
            font-size: 0.8rem;
            text-align: center;
            padding: 0 45px 0 15px;
            color: #fff;
            font-weight: normal;
        }
    }
}

#page-top {
    position: fixed;
    margin: 0;
    bottom: 60px;
    right: 0px;
    height: 45px;
    width: 45px;
    background: $dark-gray;
    a {
        display: block;
    }
    a:after {
        font-family: 'FontAwesome';
        content: "\f077";
        position: fixed;
        bottom: 70px;
        right: 12px;
        color: #fff;
    }
    a:hover {
        text-decoration: none;
        background: #999;
    }
}

ul#bottom_menu {
    position: fixed;
    margin: 0;
    padding: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    list-style: none;
    z-index: 50;
    li {
        height: 60px;
        font-size: 0.85rem;
    }
    li.reserve {
        width: 40%;
        float: left;
        display: table-cell;
        padding: 4px;
        background-color: $red;
        a {
            display: block;
            color: #fff;
            span {
                font-size: 1.1rem;
                height: 41px;
                width: auto;
            }
        }
    }
    li.mail {
        width: 27%;
        float: left;
        display: table-cell;
        padding: 4px;
        background-color: $yellow;
        a {
            color: #fff;
            display: block;
            span {
                font-size: 1.1rem;
                height: 41px;
                width: auto;
            }
        }
    }

    li.presc {
        width: 33%;
        float: left;
        display: table-cell;
        background-color: $green;
        padding: 4px;
        a {
            display: block;
            color: #fff;
            div {
                padding-top: 4px;
                height: 30px;

                img {
                    height: 24px;
                    width: auto;
                    line-height: 24px
                }
            }
        }
    }
}

@media screen and (min-width: 600px) {
    #footer {
        .clinic_info {
            padding-top: 20px;
            ul li {
                padding-bottom: 6px;
            }
        }
        .foot3 {
            width: 90%;
            height: 240px;
            #foot_map {
                display: table-cell;

                margin: 0 auto;
                line-height: 240px;
                img {
                    vertical-align: middle;
                    height: 200px;
                }
            }
        }
        .foot2 {
            display: table;
            height: 240px;
            width: 100%;
            margin: 0 auto;
            line-height: 240px;
            .clinic_info {
                vertical-align: middle;
                height: 200px;
            }
        }
        .copyright small {
            margin: 0 auto;
            display: table;
        }
    }
}

@media screen and (min-width: 768px) {
    #footer {
        h3 {
            font-size: 1.2rem;
        }
        .foot2 {
            display: table;
            height: 300px;
            width: 100%;
            margin: 0 auto;
            line-height: 300px;
            .clinic_info {
                vertical-align: middle;
                height: 200px;
            }
        }
        .foot3 {
            display: table-cell;
            height: 310px;
            line-height: 310px;
            #foot_map {
                margin: 0 auto;
                padding: 35px 0;
                img {
                    height: 240px;
                }
            }
        }
        .copyright small {}
    }
}

@media screen and (min-width: 900px) {
    #bottom_menu {
        display: none;
    }
    #page-top {
        position: fixed;
        margin-bottom: 0;
        bottom: 0;
        right: 0;
        height: 60px;
        width: 60px;
        background: $dark-gray;
        a {
            display: block;
        }
        a:after {
            font-family: 'FontAwesome';
            content: "\f077";
            position: fixed;
            bottom: 20px;
            right: 20px;
            color: #fff;
        }
        a:hover {
            text-decoration: none;
            background: #999;
        }
    }
    #footer {
        margin-top: 20px;
        padding-bottom: 0;
        ul.foot-menu {
            font-size: 1rem;
        }
        .clinic_info {
            padding-top: 40px;
            font-size: 1.0rem;
        }
        .copyright {
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: $dark-gray;
            small {
                font-size: 0.8rem;
                text-align: center;
                padding: 0 60px 0 15px;
                color: #fff;
                font-weight: normal;
                margin: 0 auto;
                display: table;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #footer {
        font-size: 1.1rem;
    }
    .foot1 li a {
        font-size: 1.1rem;
    }
    .foot3 {
        display: block;
        padding: 0 5%;
        width: 90%;
        .foot_map {
            width: 280px;
        }
    }
}

.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.under {
    text-decoration: underline;
}

.through {
    text-decoration: line-through;
}

.red {
    color: $red;
}

.pink {
    color: $pink;
}

.keikou {
    background: linear-gradient(transparent 80%, #fff001 0%);
}

p {
    font-size: .9rem;
}

maincontent a {
    color: $blue;
}

#maincontent h2 {
    font-size: 1.1rem;
    padding: 4px 0 3px 8px;
    margin: 15px 0 10px 3px;
    border-bottom: 1px solid $red;
    border-left: 8px solid $red;
    font-weight: bold;
}

#maincontent h3 {
    display: inline-block;
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: $blue;
    border: solid 3px $blue;
    padding: 0.3em;
    border-radius: 0.5em;
    font-weight: bold;
    a {
        color: $blue;
    }
}

#maincontent h4 {
    color: $red;
    font-weight: bold;
    font-size: 1rem;
}

#maincontent h6 {
    color: #b22222;
    font-weight: bold;
    font-size: 1rem;
}

#maincontent table {
    border: 1px solid #dbdbdb;
    border-collapse: collapse;
    border-spacing: 0;
    tr,
    td,
    th {
        border: 1px solid #dbdbdb;
    }
    td,
    th {
        padding: 8px;
    }
}

#maincontent ul.disc {
    list-style-type: disc;
}

#maincontent ul.contact {
    font-size: 0.9rem;
}

#maincontent .half {
    font-size: 90%;
}

.entry {
    padding: 10px 5px;
}

.ryakureki {
    margin: 20px 10px 5px;

    ul {
        margin: 15px auto;
        display: table;
    }
}

/*---------- News_lidst---------*/

.news dl.news_list {
    margin: 10px auto;
    display: table;
    dd {
        padding-bottom: 15px;
    }
}

/*---------- クリニック案内---------*/

#map {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    height: 400px;
}

table.access_info {
    margin: 15px auto 30px;
    display: table;
    font-size: .9rem;
    th {
        background: $pink;
    }
}

.building_img {
    text-align: center;
    margin-top: 15px;
    img {
        width: 90%;
    }
}

.reveal-overlay #Modal1 {
    max-width: 90% !important;
    margin: 0 auto;
    .modal-head {
        background: #26A69A;
        width: 100%;
        height: 50px;
        color: #fff;
        line-heigt: 50px;
        padding: 10px;
        font-size: 1.2rem;
        margin-bottom: 15px;
    }
    h3 {
        border-bottom: solid 3px #cce4ff;
        position: relative;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 15px;
    }

    h3:after {
        position: absolute;
        content: " ";
        display: block;
        border-bottom: solid 3px #5472cd;
        bottom: -3px;
        width: 20%;

    }
    button.close-button {
        right: 32px !important;
        top: 30px!important;
        font-size: 24px!important;
        color: #fff;
    }
}

#maincontent .res_btn .button a {
    color: #fff;
}

// ----------AGA-----
table.price {
    margin: 10px auto;
    display: table;
    thead td {
        text-align: center;
        font-weight: bold;
    }
    th {
        text-align: left;
        font-size: 0.95rem;
        background: $pink;
    }
    td {
        text-align: right;
        font-size: 0.9rem;
    }
    td.color {
        background: $light-gray;
        color: $red;
        text-align: left;
    }
    tfoot td {
        text-align: center;
        background: $pink;
    }
}

table.aga_price {
    margin: 20px auto;
    tr.color {
        background: $pink;
    }
    th {
        text-align: left;
        font-size: 0.95rem;
        background: $light-gray;
    }

    th.color {
        text-align: right;
        font-size: 0.95rem;
        background: $pink;
    }
    td {
        text-align: right;
        font-size: 0.9rem;
    }
    td.color {
        background: $light-gray;
        font-weight: bold;
        text-align: left;
    }
    thead td,
    thead th {
        text-align: center;
        font-weight: bold;
        background: $dark-green;
        color: #fff;
    }
    tfoot td {
        text-align: center;
        background: $pink;
    }
}

table.kinki1 {
    margin: 15px auto;
    td {
        font-size: 0.8rem;
    }
}

table.kinki2 {
    margin: 15px auto;
    td {
        font-size: 0.8rem;
    }
}

table.repair_price {
    th {
        text-align: left;
    }
}

table.harg_price {
    margin: 10px auto;
    display: table;
    thead {
        background: $pink;
    }
    th {
        text-align: left;
    }
    .color {
        background: $pink;
    }
}

ul.harg {
    margin: 15px auto;
    width: 90%;
}

.img_table {
    margin: 15px auto;
    display: table;
}

/*---------- 薬処方---------*/

ul#mondai-box {
    margin: 10px auto !important;
    li {
        margin-bottom: 6px !important;
        text-align: center;
    }
}

#maincontent #formWrap {
    .hidden {
        display: none;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    ul.pre-form {
        margin: 0 0 0 10px;
    }

    ul.pre-form li {
        list-style: disc;
        padding: 2px;
    }
    #aga-box {
        padding: 10px 6px;
        margin: 10px auto;
        max-width: 380px;
        width: 100%;
        td.box-lt {
            padding: 2px;
            font-size: 90%;
            width: 120px;
        }
        td.box-rt {
            width: 120px;
            padding: 2px;
            font-size: 90%;
        }
        .box {
            margin: 20px 0;
        }
        table.aga-box {
            border: none;
            tbody tr {
                border: none;
                label {
                    border: none;
                }
            }
        }
    }

    #waribiki {
        padding-top: 5px;
        .bold {
            font-size: 110%;
        }
        .right {
            text-align: right;
        }
    }

    #aga-total-price {
        font-size: 120%;
        padding-top: 5px;
    }

    #aga-total-price .aga-price {
        color: #C00;
        font-size: 18px;
        padding: 0px;

    }

    #aga-total-price .aga-incltax {
        color: #C00;
        font-size: 24px;
        padding: 0px 2px;
        font-weight: bold;
    }

    #ed-box {
        padding: 10px 6px;
        margin: 10px auto;
        max-width: 380px;
        width: 100%;
    }
    #ed-box td.box-lt {
        padding: 2px;
        font-size: 90%;
        width: 160px;
    }

    #ed-box td.box-rt {
        width: 90px;
        padding: 2px;
        font-size: 90%;

    }
    table.ed-box {
        border: none;
        tbody tr {
            border: none;
            label {
                border: none;
            }
        }
    }
    #ed-box .box {
        margin: 20px 0;
    }

    #ed-total-price {
        padding-top: 5px;
        font-size: 120%;
    }

    #ed-total-price .ed-price {
        color: #C00;
        font-size: 18px;
        padding: 0px 2px;

    }

    #ed-total-price .ed-incltax {
        color: #C00;
        font-size: 24px;
        padding: 0px;
        font-weight: bold;
    }
    #aga_error_mess,
    #ed_error_mess {
        clear: both;
        text-align: left;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 5px 17px;
        color: #F00;
        font-size: 12px;
        line-height: normal;
        background-repeat: no-repeat;
        background-position: 0px 1px;
    }

    #postage {
        margin-top: 15px;
    }

    #postage p {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    p.ecohai_postage {
        color: #C00;
        font-size: 36px;
        padding: 0px 5px;
        font-weight: bold;

    }

    .ecohai_postage .yen {
        font-style: inherit;
        font-weight: normal;
        font-size: 16px;
        color: #000;
        margin-left: 5px;
    }

    #ecohai-box article p.center {
        margin-top: 10px;
    }

    #ecohai-box article p.center a {
        color: #fff !important;
    }

    margin-top:10px;
    .yamato-postage {
        padding: 15px 0 0;
        margin: 0;
        font-size: 18px;
    }

    .souryou {
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    div#souryou_p {
        color: #C00;
        font-size: 36px;
        padding: 0 10px 10px;
        font-weight: bold;
        text-align: center;
    }

    div#souryou_p span {
        color: #666;
        font-size: 14px;
        font-weight: normal;
    }

    div#total_p {
        color: #C00;
        font-size: 36px;
        padding: 0 10px 10px;
        font-weight: bold;
        text-align: center;
    }

    div#total_p span {
        color: #666;
        font-size: 14px;
        font-weight: normal;
    }


    .ssl {
        font-size: 90%;
    }

    button#showModal {
        margin-top: 10px;
    }


    input[type="date"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="date"]::-webkit-clear-button {
        -webkit-appearance: none;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 0;
        width: 36px;
        height: 36px;
        background: rgba(255, 0, 0, 0.5); // 一旦背景色を付けて、見やすくします
        color: transparent;
        cursor: pointer;
    }
}

@media screen and (min-width: 767px) {
    #maincontent #formWrap {
        #aga-box td.box-lt {
            padding: 2px 20px
        }
        #aga-box td.box-rt {
            width: 100px;
            padding: 2px;
            font-size: 100%;
        }
        #ed-box td.box-lt {
            padding: 2px 20px;
        }
        #ed-box td.box-rt {
            width: 100px;
            padding: 2px;
            font-size: 100%;

        }
    }
}
